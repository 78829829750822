import React from 'react';
import ReactDOM from 'react-dom';

//CSS
import './index.css';
//APP
import App from './components/app'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
