import React, { Component } from "react";

//CSS
import "./app.css";

//COMPONENTS
import LandingThree from "../landing-three";

//APP

export default class App extends Component {
  render() {
    return (
      <div className="app-wrapper">
        <div className="app">
          <div className="landing-three-wrapper">
            <LandingThree />
            <div id="land-three-model" className="landing-three-model"></div>
          </div>
        </div>
      </div>
    );
  }
}
