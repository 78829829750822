import {Component} from "react";
//THREE
import * as THREE from "three";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {SAOPass} from "three/examples/jsm/postprocessing/SAOPass";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
//MODELS
import dronoportModel from "../../models/dronoport-box-gltf-model-main-04.gltf";

//THREEJS

let composer;

export default class LandingThree extends Component {
    componentDidMount() {
        // === THREE.JS CODE START ===

        let width = window.innerWidth;
        let height = window.innerHeight;

        var loader = new GLTFLoader();
        var scene = new THREE.Scene();

        //CAMERA

        var camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 1000);
        //RENDERERS

        var renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
        });

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.toneMapping = THREE.ReinhardToneMapping;
        document
            .getElementById("land-three-model")
            .appendChild(renderer.domElement);

        composer = new EffectComposer(renderer);

        var saoPass = new SAOPass(scene, camera, false, false, width, height);
        saoPass.kernelRadius = 16;
        composer.addPass(saoPass);

        //ORBITCONTROLS

        var controls = new OrbitControls(camera, renderer.domElement);
        controls.autoRotate = true;
        controls.autoRotateSpeed = -0.5;
        controls.enableZoom = false;
        // controls.maxPolarAngle = 1;
        // controls.minPolarAngle = 1;
        controls.update();

        //CAMERAPOSITION

        camera.position.x = 3;
        camera.position.y = 3;
        camera.position.z = 6;
        camera.lookAt(0, 1, 0);

        //LIGHTS

        var lightOne = new THREE.DirectionalLight(0xffffff, 1.415);
        lightOne.castShadow = true;
        lightOne.position.set(1, 0, -1);
        lightOne.lookAt(0, 0, 0);
        scene.add(lightOne);

        var lightTwo = new THREE.DirectionalLight(0xffffff, 1);
        lightTwo.castShadow = true;
        lightTwo.position.set(-1, 0, 1);
        lightTwo.lookAt(0, 0, 0);
        scene.add(lightTwo);

        var hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 4);
        scene.add(hemiLight);


        let dronoportCenter = loader.load(dronoportModel, function (gltf) {
            dronoportCenter = gltf.scene;
            dronoportCenter.name = "karkasMain";
            scene.add(dronoportCenter);
        });



        var animate = function () {
            requestAnimationFrame(animate);

            camera.lookAt(0, 1, 0);
            renderer.render(scene, camera);
            controls.update();
        };

        window.addEventListener("resize", onWindowResize, false);

        function onWindowResize() {
            width = window.innerWidth;
            height = window.innerHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            renderer.setSize(width, height);
            composer.setSize(width, height);
        }

        animate();
    }


    render() {
        return true
    }
}
